import React from "react"
import PropTypes from "prop-types"

import Button from "../components/button"
import headerImage from "../images/header.png"
// import MockupContent from "./image"
// import raindrops from "../images/raindrops.png"

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>Wasatch Traditional Window Cleaning</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      We specialize in glass detailing, servicing homes and businesses all along the Wasatch Front.
    </p>
   <h2 style={{ textAlign: "center" }}>Site Under Construction.  Check back soon!</h2>
    {/* <Button onclick="location.href='mailto:jake@wasatchtraditional.com'">Contact Us</Button> */}
    {/* <div style={{ margin: 60, width: `250px`, position: "relative" }}> */}
      {/* <div style={{ clipPath: "inset(2% 5% round 2% 5%)" }}> */}
        {/* <raindrops/> */}
      {/* </div> */}
      {/* <div */}
        {/* style={{ */}
          {/* position: "absolute", */}
          {/* width: "250px", */}
          {/* top: 0, */}
        {/* }} */}
      {/* > */}
        {/* <img */}
          {/* src={raindrops} */}
          {/* alt="a window covered in clean water droplets " */}
        {/* /> */}
      {/* </div> */}
    {/* </div> */}
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
